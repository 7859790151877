/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/


/*Theme Colors*/

$light: #383f48;

.form-control, .btn-secondary:hover, .form-control:focus, .btn-secondary.active, .card-outline-danger, .card-outline-info, .card-outline-warning, .card-outline-success, .card-outline-primary, .popover-title, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple, .bootstrap-tagsinput,  .footable .pagination li a, .select2-container--default .select2-selection--single .select2-selection__rendered, .ms-container .ms-selectable li.ms-elem-selectable, .ms-container .ms-selection li.ms-elem-selection, .jsgrid-row>.jsgrid-cell, .page-item .page-link, .page-item.disabled .page-link, .minimal-faq .card .card-header, .mega-dropdown .nav-accordion .card-header{
    background-color:$dark;
}

.right-sidebar, .select2-dropdown, .dropify-wrapper, .dropify-wrapper .dropify-preview, .wizard-content .wizard>.steps>ul>li.current .step, .wizard-content .wizard>.steps>ul>li .step, .wizard-content .wizard.wizard-circle>.steps>ul>li.current:after, .wizard-content .wizard.wizard-circle>.steps>ul>li.current~li:after, .wizard-content .wizard.wizard-circle>.steps>ul>li.current~li:before, .note-editor.note-frame .note-editing-area .note-editable, .modal-content, .jsgrid-alt-row>.jsgrid-cell, .css-bar:after{
    background: $light;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .footable .pagination li a, .select2-container--default .select2-selection--single .select2-selection__rendered, .ms-container .ms-selectable li.ms-elem-selectable, .ms-container .ms-selection li.ms-elem-selection, .close, .page-item .page-link{
    color:$white;
}
/*******************
Border Colors
*******************/
.table td, .nav-tabs, hr, .form-control, ul.wysihtml5-toolbar, .tabcontent-border, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--multiple .select2-selection__choice, .bootstrap-tagsinput, .ms-container .ms-selectable li.ms-elem-selectable, .ms-container .ms-selection li.ms-elem-selection, .ms-container .ms-list, .wizard-content .wizard.wizard-circle>.steps .step, .table-bordered, .modal-footer, .modal-header, .jsgrid-header-row>.jsgrid-header-cell, .jsgrid-edit-row>.jsgrid-cell, .jsgrid-filter-row>.jsgrid-cell, .jsgrid-grid-body, .jsgrid-grid-header, .jsgrid-header-row>.jsgrid-header-cell, .jsgrid-insert-row>.jsgrid-cell, .bootstrap-table .table>thead>tr>th, .bootstrap-table .table, .fixed-table-container, .page-item .page-link, .page-item.disabled .page-link, .featured-plan .pricing-body{
    border-color:$border;
}
/*******************
/*Background Colors light*/
/*******************/
.progress, .fc-day, .css-bar > i, .dropzone, code, .popover, .grid-stack-item-content, .form-control:disabled, .form-control[readonly], .input-group-addon, .select2-container--default .select2-selection--multiple .select2-selection__choice, .ms-container .ms-selectable, .ms-container .ms-selection, .wizard-content .wizard>.actions>ul>li>a[href="#previous"], .dataTables_filter input, .jsgrid-grid-header, .jsgrid-filter-row>.jsgrid-cell, .dropdown-menu, .mega-dropdown .dropdown-menu{
    background-color: $light;
}
.grid-stack-item-content, .input-group-addon, .wizard-content .wizard>.steps>ul>li.current>a, .mailbox ul li .drop-title, .topbar .navbar-light .navbar-nav .nav-link{
    color:$white;
}
/*******************
/*Background Colors border*/
/*******************/
.fc th.fc-mon, .fc th.fc-wed, .fc th.fc-fri, .fc th.fc-sun, .fc th.fc-tue, .fc th.fc-thu, .fc th.fc-sat{
    background: $border;
}
/*******************
/*Background Colors dark*/
/*******************/
.topbar .top-navbar .app-search .srh-btn{
    background: none;
}
.btn-secondary, .glyphs.character-mapping input{
    background: $light;
    border-color: $form-brd;
    color:$bodytext;
    &:hover, &.active{
        color:$bodytext;
        border-color: $form-brd;
    }
}
input.form-control, select.form-control, textarea.form-control {
    background-color:$light;
    color:$white;
    &:hover, &.active, &:focus{
        color:$white;
        border-color: $form-brd;
    }
}
.custom-select{
   border-color: $form-brd;
    color:$bodytext;
}
.checkbox label::after, .glyphs.character-mapping li, .breadcrumb-item.active, .dropdown-menu{
    color:$bodytext;
}
.card-header, .card-footer, .jsgrid-header-row>.jsgrid-header-cell, .featured-plan .pricing-body{
    background-color:#1a1f27;
}
.vtabs .tabs-vertical li .nav-link{
    color:$bodytext;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover{
    background-color:$dark;
    border-color:$border $border $dark;
    color:#fff;
}

/*******************
/*Background Colors Transparent*/
/*******************/
.list-group-item, .checkbox label::before, .footer, .btn-outline-primary, .customvtab .tabs-vertical li .nav-link.active, .customvtab .tabs-vertical li .nav-link:hover, .customvtab .tabs-vertical li .nav-link:focus, .bd-example-popover-static, .myadmin-dd .dd-list .dd-item .dd-handle , .myadmin-dd-empty .dd-list .dd3-content, .myadmin-dd-empty .dd-list .dd3-handle, .highlight, .custom-file-control{
    background: transparent;
}
.tabs-vertical li .nav-link.active, .tabs-vertical li .nav-link:hover, .tabs-vertical li .nav-link.active:focus, .dd-item > button{
    color:$white;
}
/*******************
/*Border Colors Transparent*/
/*******************/
.comment-widgets .comment-row, .stylish-table tbody tr{
    border-left-color:transparent;
}
.calendar-events, .popover-title{
    border-color:transparent;
}
.popover.bs-tether-element-attached-bottom::after, .popover.popover-top::after{
    border-top-color:$light;
}
.popover.bs-tether-element-attached-top::after, .popover.popover-bottom::after{
    border-bottom-color:$light;
    top:-9px;
}
.popover.bs-tether-element-attached-left::after, .popover.popover-right::after{
    border-right-color:$light;
}
.popover.bs-tether-element-attached-right::after, .popover.popover-left::after{
    border-left-color:$light;
}
.bootstrap-switch .bootstrap-switch-label{
    background: $dark;

}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default, .list-group-item, .pagination>li>a, .pagination>li>span{
    color: $bodytext;
}
.list-group-item, .custom-file-control{
    border-color:$border;
}
a.list-group-item:hover{
    color:$white;
}
.bootstrap-switch{
    border-color:$border;
}
.dp-off{
    background-color: $dark!important;
}
/*******************
/*Dashboard 1*/
/*******************/
.total-revenue4{
    .ct-series-a .ct-line, .ct-series-b .ct-line{
        stroke-width:2px;
    }
}
.ct-label, .ct-grid{
    fill:rgba(255, 255, 255, 0.3);
    stroke:rgba(255, 255, 255, 0.3);
    color:rgba(255, 255, 255, 0.3);
}
.irs-line{
    opacity: 0.1;
}
.mega-dropdown .dropdown-menu{
    background-image:none;
}
